import {
  Avatar,
  Badge,
  IconButton,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Booking from 'assets/icons/colorfulIcon/booking.svg'
import BusinessInsight from 'assets/icons/colorfulIcon/business insight.svg'
import CityLedger from 'assets/icons/colorfulIcon/city-ledger.svg'
import Corporate from 'assets/icons/colorfulIcon/corporate.svg'
import DigitalMarketing from 'assets/icons/colorfulIcon/digital-marketing.svg'
import FrontDesk from 'assets/icons/colorfulIcon/front-desk.svg'
import GLInterface from 'assets/icons/colorfulIcon/GL.svg'
import GuestProfile from 'assets/icons/colorfulIcon/guest.svg'
import Settings from 'assets/icons/colorfulIcon/hotel-settings.svg'
import Package from 'assets/icons/colorfulIcon/package-redemp.svg'
import HouseKeeping from 'assets/icons/colorfulIcon/room-mgmt.svg'
import RoomService from 'assets/icons/colorfulIcon/room-service.svg'
import ServiceRequest from 'assets/icons/colorfulIcon/service-req.svg'
import SystemAdmin from 'assets/icons/colorfulIcon/sys-admin.svg'
import TravelAgent from 'assets/icons/colorfulIcon/travel-agent.svg'
import Logout from 'assets/icons/colorfulIcon/log-out.svg'
import Profile from 'assets/icons/profile.svg'
import Image from 'assets/images/images.jpg'
import { usePermissionChecker } from 'containers/helper/hooks/usePermissionChecker'
import {
  HotelPermission,
  useGetHotelPermissionQuery,
  useGetRolePermissionLazyQuery,
  useHotelNotificationUpdateMutation,
  useHotelNotifySubscription,
  useHotelNotificationListingLazyQuery,
  useGetUsersByAccountQuery
} from 'generated/graphql'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { useForm } from 'react-hook-form'
import { WSclient } from 'client'
import { Notifications } from '@material-ui/icons'
import MailIcon from '@material-ui/icons/Mail'
import AppContext from 'containers/App/Store/AppContext'
import io from 'socket.io-client';
import { SocketContext } from 'containers/Socket/SocketContext'
export const drawerWidth = 240

interface Notification {
  HotelID: string
  ReadStatus: boolean
  UserID: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        top: 64,
        height: 'calc(100% - 64px)',
      },
      zIndex: 100,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    activeListItem: {
      borderLeft: '4px solid red',
      boxShadow: '0 3px 2px -3px gray',
      backgroundColor: 'blue',
      '& $listItemText': {
        color: 'white',
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 500,
      },
      '& $listItemIcon': {
        color: 'white',
        marginLeft: '-4px',
        minWidth: '36px',
      },
    },
    listItemText: {
      fontSize: '1rem',
      fontWeight: 400,
      color: 'black',
      marginTop: 0,
      marginBottom: 0,
    },
  })
)

interface Props {
  window?: () => Window
  open: boolean
  variant: any
  onCloseDrawer: any
}

export default function LayoutDrawer(props: Props) {
  const { window, open, onCloseDrawer, variant, ...rest } = props
  const { globalState, dispatch }: any = useContext(AppContext as any)
  const classes = useStyles()
  const container =
    window !== undefined ? () => window().document.body : undefined
  let history = useHistory()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  // const [componentOpen, setComponentOpen] = React.useState(false)
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void
  const LoggedInUser = JSON.parse(localStorage.getItem('LoggedInUser'))
  const IsSuperUser = JSON.parse(localStorage.getItem('LoggedInUser'))?.superUser

  const Hotel = JSON.parse(localStorage.getItem('Hotel'))?.ID || JSON.parse(localStorage.getItem('LoggedInUser'))?.lastestAccessedEntity
  const IsQ3F = JSON.parse(localStorage.getItem('IsQ3FInterface'))
  // const [Hotel, setHotel] = useState(JSON.parse(localStorage.getItem('Hotel'))?.ID)
  const [notification, setNotification] = useState('');
  // const [socket, setSocket] = useState(null);

  const {
    data: { HotelNotify } = { HotelNotify: [] },
  } = useHotelNotifySubscription({
    variables: {
      HotelID: Hotel,
      NotificationType: 'All',
      UserID: LoggedInUser?.ID
    },
    client: WSclient,
    fetchPolicy: 'network-only',
  })

  const { handleSubmit, errors } = useForm<Notification>({})

  const [HotelNotificationUpdate] = useHotelNotificationUpdateMutation()

  const eInvoice =  JSON.parse(localStorage.getItem('Hotel'))?.Company?.isEInvIntegrated

  const updateNotification = data => {

      HotelNotificationUpdate({
        variables: {
          operationType: "update",
          input: data?.map(x => {
            return {
              ...x,
              ReadStatus: true,
              UserID: LoggedInUser?.ID
            
            }
          })
        },
      })
  }


  const [
    HotelNotification,
    {
      data: { HotelNotificationListing } = { HotelNotificationListing: [] },
    }
  ]
   = useHotelNotificationListingLazyQuery({
    fetchPolicy: 'no-cache',
  })

  // useEffect(() => {
  //   const newSocket = io(hotelxSocketUrl);

  //   // const newSocket = io('http://localhost:8000');

  //   setSocket(newSocket);
 
  //   // Cleanup function
  //   return () => {
  //     newSocket.disconnect();
  //   };
  // }, []);

  const { socket, LOGOUT }: any =
  useContext(SocketContext)  

  useEffect(() => {  
    if((Hotel)) {
      HotelNotification({
        variables: {
          NotificationType: 'All',
          HotelID: Hotel,
          UserID: LoggedInUser?.ID,
        }
      })
    }
    if (socket) {

      socket.on('notification', (notification) => {
        HotelNotification({
          variables: {
            NotificationType: 'All',
            HotelID: Hotel,
            UserID: LoggedInUser?.ID,
          }
        })
      });
    }
    
  }, [Hotel, HotelNotify.length, LoggedInUser?.ID,socket])

  const navigations = [
    // {
    //   name: 'Home',
    //   path: '/home',
    //   icon: IHome,
    //   iconSelected: IHomeActive,
    //   ACLIndex: 0,
    //   perm: HotelPermission.HomeDashBoardIgnoreView,
    // },
    {
      name: 'Booking',
      path: '/booking',
      icon: Booking,
      ACLIndex: 0,
      perm: HotelPermission.BookingIgnoreView,
      notificationData: [
        ...HotelNotificationListing.filter(x => x.NotificationType === 'CMSBOOKING')
        ]
    },

    {
      name: 'Front Desk',
      path: '/frontdesk',
      icon: FrontDesk,
      ACLIndex: 0,
      perm: HotelPermission.FrontDeskIgnoreView,
    },
    {
      name: 'Housekeeping',
      path: '/housekeeping',
      icon: HouseKeeping,
      ACLIndex: 0,
      perm: HotelPermission.HouseKeepingIgnoreView,
    },
    {
      name: 'Room Service',

      path: '/roomservice',
      icon: RoomService,
      ACLIndex: 0,
      perm: HotelPermission.RoomServiceIgnoreView,
      notificationData: [
        ...HotelNotificationListing.filter(x => x.NotificationType === 'GUESTROOMSERVICE')
      ]
    },
    {
      name: 'Service Requests',
      path: '/servicerequest',
      icon: ServiceRequest,
      ACLIndex: 0,
      perm: HotelPermission.ServiceRequestIgnoreView,
      notificationData: [
        ...HotelNotificationListing.filter(x => x.NotificationType === 'SERVICEREQUEST')
        ]
    },
    {
      name: 'Package Redemption',
      path: '/packageredemption',
      icon: Package,
      ACLIndex: 0,
      perm: HotelPermission.PackagesRedemptionIgnoreView,
    },
    {
      name: 'Corp/Govt',
      path: '/corporate',
      icon: Corporate,
      ACLIndex: 0,
      perm: HotelPermission.CorpGovtIgnoreView,
    },
    {
      name: 'Travel Agents',
      path: '/travelagent',
      icon: TravelAgent,
      ACLIndex: 0,
      perm: HotelPermission.TravelAgentIgnoreView,
    },
    // {
    //   name: 'Contact Tracing',
    //   path: '/contacttracing',
    //   icon: ContactTracing,
    //   iconSelected: ContactTracing,
    //   ACLIndex: 0,
    //   perm: HotelPermission.TravelAgentIgnoreView,
    // },
    {
      name: 'Guest Profile',
      path: '/guestprofile',
      icon: GuestProfile,
      ACLIndex: 0,
      perm: HotelPermission.GuestProfileIgnoreView,
    },
    {
      name: 'Digital Marketing',
      path: '/digitalmarketing',
      icon: DigitalMarketing,
      ACLIndex: 0,
      perm: HotelPermission.DigitalMarketingIgnoreView,
    },

    {
      name: 'Checkout Adjustment',
      path: '/checkoutadjustment',
      icon: GLInterface,
      ACLIndex: 0,
      perm: HotelPermission.CheckoutAdjustmentIgnoreView,
    },
    {
      name: 'Business Insights',
      path: '/analytics',
      icon: BusinessInsight,
      ACLIndex: 0,
      perm: HotelPermission.BusinessInsightIgnoreView,
    },
    {
      name: 'Digital Document',
      path: '/digitaldocument',
      icon: BusinessInsight,
      ACLIndex: 0,
      perm: HotelPermission.BusinessInsightDigitalDocumentView,
    },
    {
      name: 'Digital Reporting',
      path: '/digitalreport',
      icon: BusinessInsight,
      ACLIndex: 0,
      perm: HotelPermission.BusinessInsightDigitalReportView,
    },
    {
      name: 'City Account',
      path: '/cityledger',
      icon: CityLedger,
      ACLIndex: 0,
      perm: HotelPermission.CityLedgerIgnoreView,
    },
    {
      name: 'Account Journals',
      path: '/account-journals',
      icon: GLInterface,
      ACLIndex: 0,
      perm: HotelPermission.AcctJournalIgnoreView,
    },
    eInvoice === true
      ? {
        name: 'Consolidated e-Invoice',
        path: '/consolidated',
        icon: GLInterface,
        ACLIndex: 0,
        perm: HotelPermission.ConsolidatedEInvoiceIgnoreView,
      } : null,
    IsQ3F === true
      ? {
          name: 'Q3F Interface',
          path: '/q3f-interface',
          icon: GLInterface,
          // ACLIndex: 0,
        }
      : null,
    {
      name: 'Hotel Settings',
      path: '/settings',
      icon: Settings,
      ACLIndex: 0,
      perm: HotelPermission.HotelSettingsIgnoreView,
    },
    {
      name: 'Common Settings',
      path: '/common-settings',
      icon: Settings,
      ACLIndex: 0,
      perm: HotelPermission.CommonSettingsIgnoreView,
    },
    IsSuperUser
      ? {
          name: 'System Admin',
          path: '/systemadmin',
          icon: SystemAdmin,
          // iconSelected: SettingsSelected,
          ACLIndex: 0,
          perm: 'SYSTEM_ADMIN',
        }
      : null,
    // {
    //   name: 'System Admin',
    //   path: '/systemadmin',
    //   icon: SystemAdmin,
    //   iconSelected: SettingsSelected,
    //   ACLIndex: 0,
    //   perm: HotelPermission.BusinessInsightIgnoreView,
    // },
    // {
    //   name: 'Sign Out',
    //   path: '/logout',
    //   icon: ILogOut,
    //   iconSelected: ILogOutActive,
    //   ACLIndex: 0,
    // },
  ].filter(el => el !== null)

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('LoggedInUser'))?.ID) {
      forceUpdate()
    }
  }, [JSON.parse(localStorage.getItem('LoggedInUser'))?.ID])
  const { handlePermHidden }: any = usePermissionChecker()
  const {
    data: { getHotelPermission } = {
      getHotelPermission: [],
    },
  } = useGetHotelPermissionQuery({
    fetchPolicy: 'cache-and-network',
  })
  // console.log(getHotelPermission, 'getHotelPermission')
  const [
    permQuery,
    {
      loading,
      data: { getRolePermission } = {
        getRolePermission: [],
      },
    },
  ] = useGetRolePermissionLazyQuery({
    fetchPolicy: 'cache-and-network',
  })
  useEffect(() => {
    permQuery()
  }, [])

  const permissionCheck = (v) => {

    if (IsQ3F && v.name === 'Q3F Interface') {
      return true
    } else {
      return getRolePermission?.hasOwnProperty(v.perm)
    }
  }


  const drawer = (
    <div className="route-container">
      <List disablePadding>
        {navigations.map(
          (
            v,
            index //some array
          ) => {

            const roleValidPermission = permissionCheck(v);

            if (
              (v.name !== 'System Admin' &&
                // getHotelPermission[
                //   JSON.parse(localStorage.getItem('Hotel'))?.ID
                // ]?.hasOwnProperty(v.perm)
                roleValidPermission) ||
              // getRolePermission?.hasOwnProperty(v.perm)
              JSON.parse(localStorage.getItem('LoggedInUser'))?.superUser
            ) {
                return (
                  <div key={index} onClick={!isDesktop ? onCloseDrawer : null}>
                    <ListItem
                      button
                      dense
                      activeClassName={'active'}
                      divider
                      component={NavLink}
                      to={v.path}
                      onClick={() => {
                        if(v?.notificationData?.length) {
                          updateNotification(v?.notificationData)
                        }
                        localStorage.removeItem('filterValue')
                        localStorage.removeItem('FilterArgs')
                        localStorage.removeItem('Location')
                      }}
                    >
                      <ListItemIcon>
                        <img
                          className="icon-svg"
                          src={v.icon}
                          alt="drawer-icon"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                            <Badge
                              color="secondary"
                              style={{
                                paddingRight: '10px',
                                fontSize: '3px',
                              }}
                              badgeContent={v?.notificationData?.length}
                              overlap="rectangular"
                            >
                              <Typography>{v?.name}</Typography>
                            </Badge>
                          // ) : (
                          //   v.name
                          // )
                        // primary={
                        //   <>
                        //     <span>{v.name}</span>
                        //     {v?.name === 'Service Requests' ? (
                        //       <span>
                        //         <Badge
                        //           badgeContent={
                        //             HotelNotificationListing?.filter(
                        //               x =>
                        //                 x?.NotificationType === 'SERVICEREQUEST'
                        //             )?.length
                        //           }
                        //           // badgeContent={4}
                        //           color="primary"
                        //           style={{ paddingLeft: '15px' }}
                        //         ></Badge>
                        //       </span>
                        //     ) : null}
                        //   </>
                        }
                      />
                    </ListItem>

                    {/* ) : null} */}
                  </div>
                )
            }
          }
        )}
        {/* <div onClick={!isDesktop ? onCloseDrawer : null}>
          {IsSuperUser ? (
            <ListItem
              button
              dense
              activeClassName={'active'}
              divider
              component={NavLink}
              to={'/systemadmin'}
            >
              <ListItemIcon>
                <img className="icon-svg" src={SystemAdmin} alt="drawer-icon" />
                {/* <img
                  className="icon-svg"
                  src={SettingsSelected}
                  alt="drawer-icon"
                /> 
              </ListItemIcon>
              <ListItemText primary={'System Admin'} />
            </ListItem>
          ) : null}
        </div> */}
        <ListItem
          button
          dense
          activeClassName={'active'}
          divider
          component={NavLink}
          to={'/logout'}
          onClick={() => LOGOUT()}
        >
          <ListItemIcon>
            <img className="icon-svg" src={Logout} alt="drawer-icon" />
            {/* <img className="icon-svg" src={ILogOutActive} alt="drawer-icon" /> */}
          </ListItemIcon>
          <ListItemText primary={'Sign Out'} />
        </ListItem>
      </List>
    </div>
  )


  // User Profile Wrapper
  const userProfile = (
    <div className="profile-container">
      <div className="profile-avatar">
        <Avatar
          alt="avatar"
          className="avatar"
          src={
            JSON.parse(localStorage.getItem('LoggedInUser'))?.avatar || Image
          }
        />
      </div>
      <List className="profile-list" disablePadding>
        <ListItem>
          <ListItemText
            primary={
              <span className="smTitle">{LoggedInUser?.name || '-'}</span>
            }
            secondary={
              <span className="desc">{LoggedInUser?.email || '-'}</span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="simple-menu"
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              <ExpandMore />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        className="drawer-submenu"
      >
        <MenuItem
          className="drawer-dropdown"
          onClick={
            isDesktop
              ? () => {
                  history.push('/profile')
                  setAnchorEl(null)
                }
              : () => {
                  history.push('/profile')
                  setAnchorEl(null)
                  onCloseDrawer()
                }
          }
        >
          <img className="icon-dropdown-svg" src={Profile} alt="" /> Profile
        </MenuItem>
        <MenuItem
          className="drawer-dropdown"
          onClick={
            isDesktop
              ? () => {
                  LOGOUT()
                  history.push('/logout')
                  setAnchorEl(null)
                }
              : () => {
                  LOGOUT()
                  history.push('/logout')
                  setAnchorEl(null)
                  onCloseDrawer()
                }
          }
        >
          <img className="icon-dropdown-svg" src={Logout} alt="" /> Logout
        </MenuItem>
      </Menu>
    </div>
  )



  return (
    <nav className={classes.drawer} aria-label="main menu">
      <Drawer
        {...rest}
        container={container}
        variant={variant}
        anchor={'left'}
        open={open}
        onClose={onCloseDrawer}
        className="drawer"
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {userProfile}
        {drawer}
      </Drawer>
    </nav>
  )
}
